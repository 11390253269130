/* eslint-disable prettier/prettier */
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { Container, Hero, Columns } from 'react-bulma-components'
import { Link as ScrollLink } from 'react-scroll'

// Components
import SiteNav from 'components/navbar'
import Separator from 'components/separator'
import LogoSvg from './logo.svg'

function HeroComponent() {
  return (
    <Hero className="bg-orange-pattern-hero is-small">
      <div className="rectangle-1479" />
      <div className="rectangle-1657" />
      <Hero.Body class="pb-0">
        <Container className="is-widescreen">
          <div className="columns content is-vcentered is-multiline is-centered has-text-centered-mobile mt-5 pt-5">
            <div className="column is-12-desktop is-12-widescreen is-12-tablet is-12-mobile ">
              <div class="column is-10-desktop is-centered has-text-centered is-6-mobile is-offset-3-mobile is-6-tablet is-offset-1-tablet">
                <StaticImage src="https://www.datocms-assets.com/20955/1579120308-secondary-logo-white-v3.png" />
              </div>
              <h3 class="is-size-4 is-size-5-tablet is-size-6-mobile has-font-weight-300 has-text-centered">
                Wednesday 31st January 2024 11am GMT
              </h3>
              <h1 className="is-size-2 is-size-3-tablet is-size-3-mobile has-font-weight-500  is-top-marginless mt-2 mt-3-mobile has-text-centered is-centered">
                Free Webinar
                <p class="has-text-white">
                  Turbo-Charge Your <br /> App Growth
                </p>
              </h1>

              <div class="columns is-centered">
                <div class="column is-4-desktop has-text-centered">
                  <h2 class="is-size-4 is-size-5-tablet is-size-5-mobile has-font-weight-300 has-text-centered has-text-white">
                    (without wasting time)
                  </h2>
                  <ScrollLink
                    to="subscribeSection"
                    smooth
                    duration={500}
                    className="button is-leafy is-medium is-secondary mt-4"
                  >
                    Reserve a Spot
                  </ScrollLink>
                </div>
              </div>

              <StaticImage
                src="https://www.datocms-assets.com/20955/1704805967-pocketworks-team.png"
                alt="Pocketworks team"
                placeholder="tracedSVG"
                height={547}
              />
            </div>

            <Separator className="is-hidden mt-3" rtl />
          </div>
        </Container>
      </Hero.Body>
    </Hero>
  )
}

// HeroComponent.propTypes = {
//   featuredMedia: PropTypes.object,
// }

export default HeroComponent
