import React from 'react'
import { Link } from 'gatsby'
import { Section, Columns, Box } from 'react-bulma-components'
import { StaticImage } from 'gatsby-plugin-image'

function About() {
  return (
    <Section className="has-background-white-ter my-0 pt-0 content">
      <Columns>
        <Columns.Column className="is-10 is-offset-1 has-text-centered-mobile">
          <Box className="is-rounded px-5 py-6 bg-orange-speakers position-relative">
            <div class="rectangle-1623" />
            <div class="rectangle-1622" />
            <Columns className="is-centered">
              <Columns.Column className="is-12 has-text-centered">
                <h2 className="has-text-white">About the speakers</h2>
              </Columns.Column>
            </Columns>
            <div class="columns is-variable is-4 is-flex-desktop is-align-items-center">
              <div class="column is-3-desktop is-12-mobile is-offset-1">
                <StaticImage
                  className=""
                  src="https://www.datocms-assets.com/20955/1704799235-tobin-speaker.png"
                  alt="Pocketworks Testimonial Client"
                  height={343}
                />
              </div>
              <div class="column is-6-desktop is-12-mobile">
                <h4 className="mb-0">About Tobin</h4>
                <p className="is-size-6">
                  Hi, I'm Tobin and I'm the founder of Pocketworks. I love
                  helping businesses increase their odds of success with mobile
                  apps. Twenty years ago I started my career as a software
                  engineer, helping organisations such as Wells Fargo, British
                  Telecom and the NHS. I built yUML.me, a tool for software
                  architects which is used by Intel, Microsoft and Lockheed
                  Martin.{' '}
                </p>
              </div>
            </div>
            <div class="columns is-variable is-4 is-flex mob-flex-reverse	 is-align-items-center">
              <div class="column is-6-desktop is-12-mobile is-offset-1">
                <h4 className="mb-0">About Lucy</h4>
                <p className="is-size-6">
                  Hi I'm Lucy, Mobile Growth Manager at Pocketworks. My role is
                  all about helping us and our clients understand how apps are
                  performing, grow a user base and keep users engaged. My
                  background is in marketing, and I have over 15 years of
                  experience across a broad range of projects and organisations.
                  I’ve worked for both B2B and D2C companies and have experience
                  across agency and in-house.{' '}
                </p>
              </div>
              <div class="column is-3-desktop is-12-mobile">
                <StaticImage
                  className=""
                  src="https://www.datocms-assets.com/20955/1704797394-lucy-speacer.png"
                  alt="Pocketworks Testimonial Client"
                  height={343}
                />
              </div>
            </div>
            <div class="columns is-variable is-4 is-flex-desktop is-align-items-center">
              <div class="column is-3-desktop is-12-mobile is-offset-1">
                <StaticImage
                  className=""
                  src="https://www.datocms-assets.com/20955/1704799567-anna-speaker.png"
                  alt="Pocketworks Testimonial Client"
                  height={343}
                />
              </div>
              <div class="column is-6-desktop is-12-mobile">
                <h4 className="mb-0">About Anna</h4>
                <p className="is-size-6">
                  Hey! I’m Anna, and I work as a UX/Service Designer and Product
                  Owner at Pocketworks. I’m passionate about research and
                  gathering data and insights and making sense of complex
                  services so that the team as a whole can understand them,
                  allowing for a clear focus on the solution we go on to
                  develop. I love talking to people and discovering new ways of
                  looking at problems - or at life in general really.
                </p>
              </div>
            </div>
            <div class="columns is-variable is-4 is-flex mob-flex-reverse is-align-items-center">
              <div class="column is-6-desktop is-12-mobile is-offset-1">
                <h4 className="mb-0">About Judit</h4>
                <p className="is-size-6">
                  Hola, I am Judit and I have joined Pocketworks as a Digital
                  Product Designer & PO, I love turning problems into
                  opportunities that deliver value. It is a multidisciplinary
                  role and my background has given me empathetic skills to
                  deliver the best possible UX for end-users, and our clients.{' '}
                </p>
              </div>
              <div class="column is-3-desktop is-12-mobile ">
                <StaticImage
                  className=""
                  src="https://www.datocms-assets.com/20955/1704799790-judit-speaker.png"
                  alt="Pocketworks Testimonial Client"
                  height={343}
                />
              </div>
            </div>
          </Box>
        </Columns.Column>
      </Columns>
    </Section>
  )
}

export default About
