import PropTypes from 'prop-types'
import React from 'react'

// Layout
import Layout from 'layout'

// Page Components
import WebinarSubscribeComponent from 'components/webinar-subscribe'
import Hero from './components/hero'
import HowCanWeHelp from './components/how-can-we-help'
import Clients from './components/clients'
import Blogs from './components/blogs'
import Services from './components/services'
import ArrangeChat from './components/arrange-chat'
import About from './components/about'
import WhoIsItFor from './components/who-is-it-for'
import Agenda from './components/agenda'
import AboutTheSpeakers from './components/about-the-speakers'
import AboutUs from './components/about-us'

function Homepage({ data }) {
  return (
    <Layout>
      <Hero />
      <HowCanWeHelp />
      <WhoIsItFor />
      <Agenda />
      <About />
      <AboutTheSpeakers />
      <AboutUs />
      <WebinarSubscribeComponent />
    </Layout>
  )
}

Homepage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Homepage
