import React from 'react'
import { Link } from 'gatsby'
import { Section, Columns, Box } from 'react-bulma-components'
import { StaticImage } from 'gatsby-plugin-image'
import { Link as ScrollLink } from 'react-scroll'

function About() {
  return (
    <Section className="has-background-white-ter mb-0 content">
      <Columns>
        <Columns.Column className="is-10 is-offset-1">
          <Box className="is-rounded px-6-desktop py-6-desktop has-background-grey-light position-relative">
            <Columns className="is-inline-flex py-3-desktop">
              <Columns.Column className="is-12 has-text-centered-mobile">
                <h3 className="is-orange is-size-2 is-size-3-tablet is-size-3-mobile">
                  What you'll get at the end
                </h3>
                <h3>Free App Rescue Kit</h3>
                <p>
                  Best practices required to
                  <br /> rescue a failing mobile app
                  <br />
                  <br />
                  Tips on How to develop a<br /> growth mindset.
                  <br />
                  <br />
                  Advice on continually improve your
                  <br /> app and grow your business.
                </p>
                <ScrollLink
                  to="subscribeSection"
                  smooth
                  duration={500}
                  className="button is-leafy is-orange-flat is-medium"
                >
                  Reserve a Spot
                </ScrollLink>
              </Columns.Column>
              <StaticImage
                src="https://www.datocms-assets.com/20955/1705314350-free-app-rescue-kit.png"
                alt="pocketworks free app rescue kit"
                className="image-absolute-bottom-right is-hidden-touch"
                height={300}
              />
            </Columns>
          </Box>
        </Columns.Column>
      </Columns>
    </Section>
  )
}

export default About
