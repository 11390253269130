/* eslint-disable prettier/prettier */
import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Box, Section, Container, Columns } from 'react-bulma-components'

// Components
import Separator from 'components/separator'

function HowCanWeHelp() {
  return (
    <Section className="has-bg-orange-flat">
      <Container className="content is-centered">
        <Columns>
          <Columns.Column className="is-12 has-text-centered ">
            <h2 class="has-text-white">Who's the webinar for?</h2>
          </Columns.Column>
        </Columns>
        <Columns className="is-centered">
          <Columns.Column className="is-9 ">
            <Columns className="is-centered">
              <Columns.Column className="is-3-desktop is-6-tablet">
                <Box className="is-rounded is-equal-height has-background-white-ter has-text-centered">
                  <div class="p-5">
                    <StaticImage
                      src="https://www.datocms-assets.com/20955/1705315262-pocketworks_app_growth_process_illustration.png"
                      alt="Pocketworks app growth process illustration"
                    />
                  </div>
                  <h3 className="is-marginless mt-2 pb-3 is-orange">
                    Business Owners
                  </h3>
                </Box>
              </Columns.Column>
              <Columns.Column className="is-3-desktop is-6-tablet">
                <Box className="is-rounded is-equal-height has-background-white-ter has-text-centered">
                  <div class="p-5">
                    <StaticImage
                      src="https://www.datocms-assets.com/20955/1705315173-pocketworks_app_growth_illustration.png"
                      alt="Pocketworks app growth illustration"
                    />
                  </div>

                  <h3 className="is-marginless mt-2 pb-3 is-orange">
                    Product Managers
                  </h3>
                </Box>
              </Columns.Column>
              <Columns.Column className="is-3-desktop is-6-tablet">
                <Box className="is-rounded is-equal-height has-background-white-ter has-text-centered">
                  <div class="p-5">
                    <StaticImage
                      src="https://www.datocms-assets.com/20955/1705314925-man-sitting-with-laptop-illustration.png"
                      alt="Pocketworks developer illustration"
                    />
                  </div>
                  <h3 className="is-marginless mt-2 pb-3 is-orange">
                    App Developers
                  </h3>
                </Box>
              </Columns.Column>
              <Columns.Column className="is-3-desktop is-6-tablet">
                <Box className="is-rounded is-equal-height has-background-white-ter has-text-centered">
                  <div class="p-5">
                    <StaticImage
                      src="https://www.datocms-assets.com/20955/1648122592-pocketworks-app-developers-uk-illustration.jpeg"
                      alt="Pocketworks app developers uk illustration"
                    />
                  </div>

                  <h3 className="is-marginless mt-2 pb-3 is-orange">Teams</h3>
                </Box>
              </Columns.Column>
            </Columns>
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  )
}

export default HowCanWeHelp
