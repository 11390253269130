/* eslint-disable prettier/prettier */
import React from 'react'
import { Box, Section, Container, Columns } from 'react-bulma-components'

// Components
import { Link as ScrollLink } from 'react-scroll'
import DateSvg from './date.svg'
import TimeSvg from './time.svg'
import DurationSvg from './duration.svg'
import PriceSvg from './price.svg'

function HowCanWeHelp() {
  return (
    <Section className="has-background-grey-white-ter ">
      <Container className="content is-widescreen">
        <Columns className="is-centered">
          <Columns.Column className="is-9">
            <Columns className="is-inline-flex has-text-centered-mobile">
              <Columns.Column className="is-4-desktop is-12-mobile content ">
                <h3 class="has-text-weight-light is-size-2 is-size-3-tablet is-size-3-mobile">
                  Webinar
                  <br />
                  Intro
                </h3>
              </Columns.Column>

              <Columns.Column className="is-6 is-offset-1-desktop">
                <p className="is-size-4">
                  In a dynamic digital landscape where consumer preferences
                  evolve rapidly, maintaining a successful mobile app can be a
                  real challenge.
                  <br />
                  <br /> In this webinar, we will delve into the actionable
                  strategies and tactics that help build continuous growth.
                </p>
              </Columns.Column>
            </Columns>
            <Columns className="mt-6">
              <Columns.Column className="is-3-desktop is-6-tablet">
                <Box className="is-rounded is-equal-height has-background-white-ter has-text-centered">
                  <div>
                    <img height={50} width={63} src={DateSvg} alt="Date" />
                  </div>

                  <h2 className="has-text-dark is-size-6 mt-3">Date</h2>
                  <p className="">31st January 2024</p>
                </Box>
              </Columns.Column>
              <Columns.Column className="is-3-desktop is-6-tablet">
                <Box className="is-rounded is-equal-height has-background-white-ter has-text-centered">
                  <div>
                    <img height={50} width={63} src={TimeSvg} alt="Time" />
                  </div>

                  <h2 className="has-text-dark is-size-6 mt-3">Time</h2>
                  <p className="">11am GMT</p>
                </Box>
              </Columns.Column>
              <Columns.Column className="is-3-desktop is-6-tablet">
                <Box className="is-rounded is-equal-height has-background-white-ter has-text-centered">
                  <div>
                    <img
                      height={50}
                      width={63}
                      src={DurationSvg}
                      alt="Duration"
                    />
                  </div>

                  <h2 className="has-text-dark is-size-6 mt-3">Duration</h2>
                  <p className="">1 Hour</p>
                </Box>
              </Columns.Column>
              <Columns.Column className="is-3-desktop is-6-tablet">
                <Box className="is-rounded is-equal-height has-background-white-ter has-text-centered">
                  <div>
                    <img height={50} width={63} src={PriceSvg} alt="Price" />
                  </div>

                  <h2 className="has-text-dark is-size-6 mt-3">Price</h2>
                  <p className="">Free</p>
                </Box>
              </Columns.Column>
            </Columns>
            <Columns.Column className="is-12-desktop is-6-tablet is-centered has-text-centered">
              <p class="mt-5">
                <ScrollLink
                  to="subscribeSection"
                  smooth
                  duration={500}
                  className="button is-leafy is-medium is-orange-flat"
                >
                  Reserve a Spot
                </ScrollLink>
              </p>
            </Columns.Column>
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  )
}

export default HowCanWeHelp
