import React from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { Section, Columns, Box } from 'react-bulma-components'
import { Element } from 'react-scroll'

function StrategySubscribeComponent() {
  const handleFormSubmit = async (e) => {
    console.log('Submitting')
    e.preventDefault()
    const myForm = document.getElementById('webinar-app-growth-form')
    const formData = new FormData(myForm)
    document.querySelector('#form-submit-button').innerText = 'Sending...'
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        console.log('Form successfully submitted')
        alert('Form successfully submitted')
      })
      .catch((error) => alert(error))
  }

  return (
    <Element name="subscribeSection">
      <Section className="has-background-white-ter mb-0 content">
        <Columns>
          <Columns.Column className="is-10 is-offset-1">
            <Box className="is-rounded px-6 pt-6 pb-0 has-bg-orange position-relative">
              <div class="columns  has-text-centered">
                <div class="column is-4-desktop is-12-mobile is-offset-4-desktop">
                  <h3 class="">Reserve your spot</h3>{' '}
                  <form
                    name="webinar-app-growth-form"
                    id="webinar-app-growth-form"
                    method="POST"
                    data-netlify="true"
                    netlify-honeypot="bot-field"
                  >
                    <input
                      type="hidden"
                      name="form-name"
                      value="webinar-app-growth-form"
                    />
                    <input type="hidden" name="tag" value="776186" />

                    <div className="field">
                      <div className="control has-icons-left has-icons-right">
                        <input
                          className="input is-rounded"
                          type="email"
                          name="email"
                          placeholder="Email"
                          required
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-envelope" />
                        </span>
                        <span className="is-hidden icon is-small is-right">
                          <i className="fas fa-exclamation-triangle" />
                        </span>
                      </div>
                    </div>

                    <label>
                      <span class="has-text-white">You're in control</span>
                      <br />
                      No spam, just content you crave.
                    </label>

                    <div className="control mt-5 is-expanded">
                      <button
                        className="button is-black is-fullwidth"
                        id="form-submit-button"
                        type="submit"
                      >
                        Reserve your spot
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <StaticImage
                src="https://www.datocms-assets.com/20955/1704805967-pocketworks-team.png"
                alt="Pocketworks team"
                placeholder="tracedSVG"
                height={547}
              />
            </Box>
          </Columns.Column>
        </Columns>
      </Section>
    </Element>
  )
}

export default StrategySubscribeComponent
