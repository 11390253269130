/* eslint-disable prettier/prettier */
import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Box, Section, Container, Columns } from 'react-bulma-components'

// Components
import Separator from 'components/separator'
import DateSvg from './date.svg'
import TimeSvg from './time.svg'
import DurationSvg from './duration.svg'
import PriceSvg from './price.svg'

function HowCanWeHelp() {
  return (
    <Section className="has-background-grey-white-ter is-medium">
      <Container className="content is-widescreen">
        <Columns className="is-centered">
          <Columns.Column className="is-10 has-text-centered-mobile">
            <Columns className="is-inline-flex">
              <Columns.Column className="is-6-desktop is-12-mobile ">
                <h3 class="is-orange">ABOUT US</h3>
              </Columns.Column>

              <Columns.Column className="is-6-desktop ">
                <h2 class="is-size-4">Pocketworks</h2>
                <p className="is-size-5">
                  We're the app developers for when you need to bulid and grow
                  the right app for your business, backed by customer insight
                  and data.
                  <br />
                  <br />
                  Our sweet spot is developing apps that get real results by
                  leveraging user research, data and growth marketing. Founded
                  in 2012, we've grown from a team of four in Leeds UK to
                  nineteen people across five European countries.
                </p>
                <StaticImage
                  src="https://www.datocms-assets.com/20955/1705314374-pocketworks-team-meeting.png"
                  alt="pocketworks team meeting"
                  height={200}
                />
              </Columns.Column>
            </Columns>
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  )
}

export default HowCanWeHelp
