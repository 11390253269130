/* eslint-disable prettier/prettier */
import React from 'react'
import { Box, Section, Container, Columns } from 'react-bulma-components'
import { Link as ScrollLink } from 'react-scroll'

// Components

function HowCanWeHelp() {
  return (
    <Section className="has-background-grey-white-ter is-medium">
      <Container className="content is-widescreen">
        <Columns className="is-centered">
          <Columns.Column className="is-9 ">
            <Columns className="is-inline-flex ">
              <Columns.Column className="is-3-desktop is-12-mobile">
                <h2 class="has-text-weight-light is-size-2 is-size-3-tablet is-size-3-mobile has-text-centered-mobile">
                  Webinar
                  <br />
                  Agenda
                </h2>
              </Columns.Column>

              <Columns.Column className="is-9 ">
                <Box className="is-rounded p-8">
                  <Columns className="is-inline-flex">
                    <Columns.Column className="is-1-desktop is-1-mobile is-1-tablet">
                      <h3 class="is-size-3 is-orange">1</h3>
                    </Columns.Column>
                    <Columns.Column className="is-10-desktop is-10-mobile is-10-tablet">
                      <h2 class="is-size-4">Spotting App Decline</h2>
                      <p className="is-size-5">
                        Ways to spot your mobile app is in decline - the key
                        metrics that show there is a problem
                      </p>
                    </Columns.Column>
                  </Columns>
                </Box>
                <Box className="is-rounded p-8">
                  <Columns className="is-inline-flex">
                    <Columns.Column className="is-1-desktop is-1-mobile is-1-tablet">
                      <h3 class="is-size-3 is-orange">2</h3>
                    </Columns.Column>
                    <Columns.Column className="is-10-desktop is-10-mobile is-10-tablet">
                      <h2 class="is-size-4">Uncover Specific Problems</h2>
                      <p className="is-size-5">
                        How to uncover your specific problems (and how to
                        prioritise them)
                      </p>
                    </Columns.Column>
                  </Columns>
                </Box>
                <Box className="is-rounded p-8">
                  <Columns className="is-inline-flex">
                    <Columns.Column className="is-1-desktop is-1-mobile is-1-tablet">
                      <h3 class="is-size-3 is-orange">3</h3>
                    </Columns.Column>
                    <Columns.Column className="is-10-desktop is-10-mobile is-10-tablet">
                      <h2 class="is-size-4">Taking Action</h2>
                      <p className="is-size-5">
                        How to take action - we’ll take you through a real world
                        client example with practical tips and advice
                      </p>
                    </Columns.Column>
                  </Columns>
                </Box>
                <Box className="is-rounded p-8">
                  <Columns className="is-inline-flex">
                    <Columns.Column className="is-1-desktop is-1-mobile is-1-tablet">
                      <h3 class="is-size-3 is-orange">4</h3>
                    </Columns.Column>
                    <Columns.Column className="is-10-desktop is-10-mobile is-10-tablet">
                      <h2 class="is-size-4">Making Growth a Priority</h2>
                      <p className="is-size-5">
                        How to make growth a priority - developing a growth
                        mindset to keep your app healthy in the long run
                      </p>
                    </Columns.Column>
                  </Columns>
                </Box>
                <Box className="is-rounded p-8">
                  <Columns className="is-inline-flex">
                    <Columns.Column className="is-1-desktop is-1-mobile is-1-tablet">
                      <h3 class="is-size-3 is-orange">5</h3>
                    </Columns.Column>
                    <Columns.Column className="is-10-desktop is-10-mobile is-10-tablet">
                      <h2 class="is-size-4">Q&A</h2>
                      <p className="is-size-5">
                        How to make growth a priority - developing a growth
                        mindset to keep your app healthy in the long run
                      </p>
                    </Columns.Column>
                  </Columns>
                </Box>
                <p class="mt-5 has-text-centered-mobile">
                  <ScrollLink
                    to="subscribeSection"
                    smooth
                    duration={500}
                    className="button is-leafy is-orange-flat is-medium"
                  >
                    Reserve a Spot
                  </ScrollLink>
                </p>
              </Columns.Column>
            </Columns>
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  )
}

export default HowCanWeHelp
